import React from 'react';
import {CardActionArea, Card, CardHeader} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 345,
        fontFamily: theme.typography.fontFamily
    },
}));

export default function CardOption( props ){

    const classes = useStyles();

    return(
        <React.Fragment>

            <Card className={classes.card}>
                <CardActionArea href={props.link}>
                        <CardHeader
                            action={
                                <IconButton aria-label="Ver">
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            }
                            title={props.title}
                            subheader={props.subheader}
                        />
                </CardActionArea>
            </Card>

        </React.Fragment>
    )
}