import {Container, Typography} from '@material-ui/core';
import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import SPLoSentimos from '../images/WIP-Serendipia.jpg';

function NotFound(){
    return(
        <React.Fragment>
            <Helmet>
                {/*<meta name="description" content="En este micrositio puedes consultar y visualizar los contratos que el gobierno federal ha hecho para atender la pandemia de COVID-19" />*/}
                <title>{`No se encontró el resultado`}</title>
            </Helmet>

            <Container maxWidth="lg" className={'section-content'}>


                <img className={'img-sp-sorry'} src={SPLoSentimos} alt="Lo sentimos imagen" />

                <Typography className={'section-content'} color="textSecondary" variant="body2">
                    Explora las secciones del menú o regrese al inicio  <Link to="/"> aquí </Link>
                </Typography>
            </Container>

        </React.Fragment>
    );
}
export default NotFound;
