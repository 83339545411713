
export function getUnicosList(lista, columna){
  const busca = lista.map( item => item[columna])
  const unicos = new Set(busca);
  return unicos
}

export function removeAccents (str) {
  return (str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toLowerCase();
}

export function resPath(row){
  if (row['RFC'] !== '-' || row['RFC'] !== ''){
    return removeAccents(row['RFC'])
  }else if( row['RFC'] === '-'){
    return removeAccents(row['Proveedor o contratista'].replace(/\s/g, '-'))
  }
}

export function removecommas( string ){
  return string.replace(/,/g,'').replace(/\./g,'').replace(/"/g,'')
}
