import {Container, Grid,} from '@material-ui/core';
import React from 'react';
import {Helmet} from "react-helmet";
import Hero from "../components/Hero";
import CardOption from "../components/CardOption";
import CardCont from '../components/CardCont';

import contratos from '../contratos.json'


function Home(){

    let instituciones = new Set ( contratos.map( item => item['Institución'] ) )
    let proveedores = new Set ( contratos.map( item => item['Proveedor o contratista'] ) )

    const total = contratos.reduce((a,b)=> a + (+b['Importe del contrato']),0 )


    return(
        <React.Fragment>
            <Helmet>
                <meta property="og:title" content='Inicio'/>
                <meta property="og:description"
                    content="En este micrositio puedes consultar y visualizar los contratos que el gobierno federal ha hecho para el proyecto Tren Maya"/>
                <meta name="description"
                    content="En este micrositio puedes consultar y visualizar los contratos que el gobierno federal ha hecho para el proyecto Tren Maya" />
                <title>Inicio</title>
            </Helmet>


            <Hero />

            <Container maxWidth="lg" className={'section-content'}>

                <Grid container justifyContent="space-around" alignItems="center">

                    <Grid container spacing={1}>
                        <Grid item md={3} sm={6} xs={12}>
                            <CardCont
                                number={Intl.NumberFormat('en-US',{style:'currency',currency:'MXN',minimunFractionDigits:2}).format(parseFloat(total)) }
                                tag='Monto total'/>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <CardCont
                            number={contratos.length}
                            tag='Número de contratos' />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <CardCont
                                number={instituciones.size}
                                tag='Número de intituciones' />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <CardCont
                                number={proveedores.size}
                                tag='Número de proveedores' />
                        </Grid>
                    </Grid>

                    <Grid container className={'section-content'} justifyContent="space-around">
                        <Grid item xs={12} sm={3}>
                            <CardOption
                                title = "Contratos Tren Maya"
                                subheader = "Contratos reportados en CompraNet del 2018 al 2021"
                                link = {'/contratos'}
                            />
                        </Grid>
                    </Grid>

                    

                </Grid>

            </Container>


        </React.Fragment>
    );
}
export default Home;
