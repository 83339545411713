import React from 'react';

import {Helmet} from "react-helmet";
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import {Grid} from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';


/* components */
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Navbar from "./components/Navbar";
import ContratosHome from "./pages/ContratosHome";
import Contrato from "./pages/Contrato";
import Institucion from "./pages/Institucion";
import Proveedor from "./pages/Proveedor";
import Footer from './components/Footer';

const theme = createMuiTheme({
    /* Especificaciones de color y tipografía en los componentes */
    palette: {
        primary: {
            light: '#fddf85',
            main: '#FCD63A',
            mainBlue: '#00BDFF',
            dark: '#dbac00',
            white: '#fff',
            bggrey: '#fffbf1',
        },
    },
    typography: {
        fontFamily: [
            '"Titillium Web"',
            'Open sans',
            'sans-serif'
        ].join(','),
    },
});

export default function App() {
  return (
          <React.Fragment>
              <Helmet>
                  <meta name="description" content="Contratos sobre el tren maya" />
                  <title>Tren maya</title>
              </Helmet>
              <BrowserRouter>
                  <ThemeProvider theme={theme}>
                      <Navbar />

                      <Grid container>

                          <Grid item  xs={12} sm={12}>
                              <Switch>
                                  <Route exact path="/" component={Home}/>

                                  <Route exact path="/contratos" component={ContratosHome}/>
                                  <Route path="/contratos/:id" component={Contrato}/>


                                  <Route path="/institucion/:id" component={Institucion}/>
                                  <Route path="/proveedor/:id" component={Proveedor}/>


                                  <Route component={NotFound}/>

                              </Switch>
                          </Grid>

                      </Grid>
                      
                      <Footer />


                  </ThemeProvider>
              </BrowserRouter>
      </React.Fragment>

  );
}
