import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section1: {
        margin: 30,
    },
    section2: {
        margin: 20,
    },
    section3: {
        margin: 20,
    },
    tittle: {
        marginTop: 20,
        color: theme.palette.primary.dark,
        fontWeight: 'normal',
    },
    labelCode: {
        color: theme.palette.primary.dark,
        fontWeight: 'bold',
    },
    labelInfo: {
        fontWeight: 'bold !important',
    },
    noteMoney: {
        marginRight: 20,
        marginLeft: 20
    }
}));

export default function InfoCard( props) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>

            <div className={classes.section1}>
                <Grid container alignItems="center">
                    <Grid item sm>
                        <Typography className={classes.tittle} gutterBottom variant="h4">
                            Información del contrato
                        </Typography>
                    </Grid>
                    <Grid item className={classes.noteMoney}>
                        <Typography gutterBottom variant="subtitle1">Moneda del contrato</Typography>
                        <span className={classes.labelCode}>{props.contrato['Moneda del contrato']}</span>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="subtitle1">Código del contrato</Typography>
                        <span className={classes.labelCode}>{props.contrato['Código del contrato']}</span>
                    </Grid>
                </Grid>
                <Typography color="textSecondary" variant="body2">
                    Esta información corresponde a lo registrado en Compranet
                </Typography>
            </div>
            <Divider variant="middle" />
            <Grid container className={classes.section1}>

                <Grid item xs={12} sm={8}>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Núm. de control del contrato
                        </Typography>
                        {props.contrato['Núm. de control del contrato']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Título del contrato
                        </Typography>
                        {props.contrato['Título del contrato']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Título del expediente
                        </Typography>
                        {props.contrato['Título del expediente']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Descripción del contrato
                        </Typography>
                        {props.contrato['Descripción del contrato']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Tipo de procedimiento
                        </Typography>
                        {props.contrato['Tipo de procedimiento']}
                    </div>

                </Grid>

                <Grid item xs={12} sm={4}>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Fecha de publicación
                        </Typography>
                        {props.contrato['Fecha de publicación']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Fecha de fallo
                        </Typography>
                        {props.contrato['Fecha de fallo']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Fecha de apertura
                        </Typography>
                        {props.contrato['Fecha de apertura']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Fecha de firma del contrato
                        </Typography>
                        {props.contrato['Fecha de firma del contrato']}
                    </div>
                </Grid>

            </Grid>
            <Divider variant="middle" />
            <Grid container className={classes.section1}>

                <Grid item xs={12} sm={4}>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Clave de la UC
                        </Typography>
                        {props.contrato['Clave de la UC']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Nombre de la UC
                        </Typography>
                        {props.contrato['Nombre de la UC']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Responsable de la UC
                        </Typography>
                        {props.contrato['Responsable de la UC']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Referencia del expediente
                        </Typography>
                        {props.contrato['Referencia del expediente']}
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>

                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Clave CUCOP
                        </Typography>
                        {props.contrato['Clave CUCOP']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Plantilla del expediente
                        </Typography>
                        {props.contrato['Plantilla del expediente']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Fundamento legal
                        </Typography>
                        {props.contrato['Fundamento legal']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Número del procedimiento
                        </Typography>
                        {props.contrato['Número del procedimiento']}
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Carácter del procedimiento
                        </Typography>
                        {props.contrato['Carácter del procedimiento']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Tipo de contratación
                        </Typography>
                        {props.contrato['Tipo de contratación']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Forma de participación
                        </Typography>
                        {props.contrato['Forma de participación']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Convenio modificatorio
                        </Typography>
                        {props.contrato['Convenio modificatorio']}
                    </div>
                </Grid>

            </Grid>
            <Divider variant="middle" />
            <Grid container className={classes.section1}>

                <Grid item xs={12} sm={4}>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Clave del programa federal
                        </Typography>
                        {props.contrato['Clave del programa federal']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Contrato marco
                        </Typography>
                        {props.contrato['Contrato marco']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Compra consolidada
                        </Typography>
                        {props.contrato['Compra consolidada']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Contrato plurianual
                        </Typography>
                        {props.contrato['Contrato plurianual']}
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>

                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Clave de cartera SHCP
                        </Typography>
                        {props.contrato['Clave de cartera SHCP']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Folio en el RUPC
                        </Typography>
                        {props.contrato['Folio en el RUPC']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Estratificación de la empresa
                        </Typography>
                        {props.contrato['Estratificación de la empresa']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Clave del país de la empresa
                        </Typography>
                        {props.contrato['Clave del país de la empresa']}
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            RFC verificado en el SAT
                        </Typography>
                        {props.contrato['RFC verificado en el SAT']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Crédito externo
                        </Typography>
                        {props.contrato['Crédito externo']}
                    </div>
                    <div className={classes.section2}>
                        <Typography className={classes.labelInfo} gutterBottom variant="body1">
                            Organismo financiero
                        </Typography>
                        {props.contrato['Organismo financiero']}
                    </div>
                </Grid>

            </Grid>

            <div className={classes.section3}>
                <Button href={props.contrato['Dirección del anuncio']} className={classes.labelCode} color="primary">Enlace de la publicación</Button>
            </div>
        </Paper>
    );
}