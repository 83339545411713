import React from 'react';
import {AppBar, Toolbar} from '@material-ui/core';
import {Link} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";


import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import SPLogo from '../images/LINEA-Logo-tren-maya.svg';

const useStyles = makeStyles((theme) => ({
    nav: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.dark,
        zIndex: '1000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logo:{
        marginRight: theme.spacing(2),
        width: 280,
        [theme.breakpoints.down('sm')]: {
            width: 240,
        },
    },
}));

export default function Navbar(){
    const classes = useStyles();

    return (
        <AppBar position="static">
            <Toolbar className={classes.nav}>
                <Link to="/" className={classes.nav}>
                    <img className={classes.logo} src={SPLogo} alt="Logo Contratos Tren Maya" />
                </Link>

                <div className={classes.nav}>

                    <Link to="/contratos">
                        <ListItem button>
                            <ListItemText primary="Contratos" />
                        </ListItem>
                    </Link>

                    <Link to="/catalogo">
                        <ListItem button>
                            <ListItemText primary="Catálogo" />
                        </ListItem>
                    </Link>
                </div>

            </Toolbar>

        </AppBar>
    );

}
