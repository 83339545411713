import React from 'react'
import {Grid, Typography} from '@material-ui/core'
//import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles'

import CardCont from '../components/CardCont'
import contratos from '../contratos.json'
import TableContent from "./TableContent";
import {removeAccents} from "../services/getList"

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      margin: '20px 0',
    },
    label:{
      fontWeight: 'bold',
      margin: '10px 7px 10px',
      fontSize: 16,
    },
    form: {
      marginRight: 7,
      backgroundColor: 'white',
      width: '100%',
    }
  }));
  
  export default function SearchCont(){
    const classes = useStyles()
    const [listaAll, setListaAll] = React.useState(contratos);
    const [palabra, setPalabra] = React.useState('');
    const [proveedores, setProveedores] = React.useState('');
    const [instituciones, setInstituciones] = React.useState('');

    const unicosGeneral={
        Proveedores :  new Set ( contratos.map( item => item['Proveedor o contratista'] ) ),
        Instituciones : new Set ( contratos.map( item => item['Institución'] ) ),
      }
  
    const stats = {
      numPorcen: '',
      instiPorcen: '',
      provPorcen: '',
    }
  
    const handleChange = (event) => {
      setPalabra(event.target.value);
    };
  
    React.useEffect( function() {
      const listnew = contratos.filter(item =>
          removeAccents(item['Título del expediente'].toString())
              .includes( removeAccents(palabra)) ||
          removeAccents(item['Descripción del contrato'].toString())
              .includes( removeAccents(palabra)) ||
          removeAccents(item['Título del contrato'].toString())
              .includes( removeAccents(palabra))||
          removeAccents(item['Proveedor o contratista'].toString())
              .includes( removeAccents(palabra))||
          removeAccents(item['Institución'].toString())
              .includes( removeAccents(palabra))||
          removeAccents(item['Siglas de la Institución'].toString())
              .includes( removeAccents(palabra))
      )
      setListaAll(listnew)
  
        let unicosInst = new Set ( listaAll.map( item => item['Institución'] ) )
        setInstituciones(unicosInst)
        let unicosProv = new Set ( listaAll.map( item => item['Proveedor o contratista'] ) )
        setProveedores(unicosProv)
  
    },[palabra,listaAll])
  

    const total = listaAll.reduce((a,b)=> a + (+b['Importe del contrato']),0 )

    stats.numPorcen = ( (listaAll.length * 100) / contratos.length )
    stats.instiPorcen = ( (instituciones.size * 100) / unicosGeneral.Instituciones.size )
    stats.provPorcen = ( (proveedores.size * 100) / unicosGeneral.Proveedores.size )
  
      return (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField onChange={handleChange} className={classes.form} id="outlined-search" placeholder="Ingresa una palabra clave a buscar" label="Buscar palabra" type="search" variant="outlined" />
            </form>
          </Grid>
  
          <Grid container className={classes.root}>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.label} variant="subtitle1">Resultados de la búsqueda</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CardCont
                  number={listaAll.length}
                  stats={stats.numPorcen}
                  tagStats = {`de los contratos relacionados ( ${Intl.NumberFormat().format(contratos.length)} )`}
                  tag='Total de contratos'/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CardCont
                  number={proveedores.size}
                  stats={stats.provPorcen}
                  tagStats = {`del los proveedores relacionados ( ${Intl.NumberFormat().format(unicosGeneral.Proveedores.size)} )`}
                  tag='Número de proveedores'/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CardCont
                  number={instituciones.size}
                  stats={stats.instiPorcen}
                  tagStats = {`de las instituciones relacionados ( ${Intl.NumberFormat().format(unicosGeneral.Instituciones.size)} )`}
                tag='Número de instituciones'/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <CardCont
                  number={Intl.NumberFormat('en-US',{style:'currency',currency:'MXN',minimunFractionDigits:2}).format(parseFloat(total)) }
                  tag='Monto mayor'/>
            </Grid>
          </Grid>


          <Grid container className={classes.root}>
            <TableContent lista={listaAll}/>
          </Grid>
        </Grid>
      );
  
  }
  
  