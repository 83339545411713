import React from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {removeAccents, removecommas} from '../services/getList'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 400,
    '&:hover': {
      fontWeight: 700,
    }
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'Título del expediente', numeric: false, disablePadding: false, label: 'Título del expediente' },
  { id: 'Institución', numeric: false, disablePadding: true, label: 'Institución' },
  { id: 'Proveedor o contratista', numeric: false, disablePadding: false, label: 'Proveedor' },
  { id: 'Fecha de inicio del contrato', numeric: true, disablePadding: true, label: 'Fecha de inicio del contrato' },
  { id: 'Fecha de fin del contrato', numeric: true, disablePadding: true, label: 'Fecha de fin del contrato' },
  { id: 'Importe del contrato', numeric: true, disablePadding: true, label: 'Importe del contrato' },
  { id: 'Detalles', numeric: false, disablePadding: false, label: 'Detalles'},
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  link: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  linkDetails: {
    color: theme.palette.primary.dark,
    '&:hover': {
      color: theme.palette.common.black
    }
  }
}));

export default function EnhancedTable( {lista} ) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('Importe del contrato');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, ] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, lista.length - page * rowsPerPage);


  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>

        <TableContainer>
          <Table
              className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
                classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={lista.length}
            />
            <TableBody>
              {stableSort(lista, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lista, index) => {



                  const pathnameinst = removecommas( removeAccents(lista['Institución'].replace(/\s/g, '-')) )
                  const pathnameProv = removecommas( removeAccents(lista['Proveedor o contratista'].replace(/\s/g, '-')) )

                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover key={labelId}>
                      <TableCell component="th" id={labelId} scope="row">
                        {lista['Título del expediente']}
                      </TableCell>
                      <TableCell align="right">
                        <Link className={classes.link} to={
                          {
                            pathname: `/institucion/${pathnameinst}`,
                          }
                        }>{lista['Siglas de la Institución']}</Link>
                        </TableCell>
                      <TableCell align="right">
                        <Link className={classes.link} to={
                          {
                            pathname: `/proveedor/${pathnameProv}`,
                          }
                        }>{lista['Proveedor o contratista']}</Link>
                        </TableCell>
                      <TableCell align="right">{lista['Fecha de inicio del contrato']}</TableCell>
                      <TableCell align="right">{lista['Fecha de fin del contrato']}</TableCell>
                      <TableCell align="right">{Intl.NumberFormat('en-US',{style:'currency',currency:'USD',minimunFractionDigits:2}).format(lista['Importe del contrato']) }</TableCell>
                      <TableCell align="right">
                          <Link className={classes.linkDetails} color="inherit" to={
                            {
                              pathname: `/contratos/${lista['Código del contrato']}`,
                            }
                          }>Ver detalles</Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={lista.length}
          rowsPerPageOptions={[10]}
          labelRowsPerPage="Filas por página"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}
