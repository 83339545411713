import React from 'react';
import {Container, Grid} from '@material-ui/core'


import TableContent from '../components/TableContent';

import all from "../contratos.json"
import CardCont from "../components/CardCont";
import {makeStyles} from "@material-ui/core/styles";    
import {Helmet} from "react-helmet";
import {removeAccents,removecommas} from "../services/getList";
// import getImportes from '../services/getImportes'


const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export default function Institucion(props){
    const classes = useStyles()
  const path = props.location.pathname
  const [,,id_inst] = path.split("/");
  const [nombre, setNombre] = React.useState([]);
  const [contratos, setContratos] = React.useState([]);
  const [proveedores, setProveedores] = React.useState([]);
  const [total, setTotal] = React.useState([]);

  React.useEffect( function() {

        const item = all.filter( item =>
            removecommas( removeAccents(item['Institución'].toString().replace(/\s/g, '-')) ) === id_inst.toString() )
        setNombre(item[0]['Institución'])

        const lista = all.filter( contrato =>
            contrato['Institución'] === nombre)
        setContratos(lista)

        const proveedores = new Set ( lista.map( item => item['Proveedor o contratista'] ) )
        setProveedores(proveedores)

        const total = lista.reduce((a,b)=> a + (+b['Importe del contrato']),0 )
        setTotal(total)

        // const totales = getImportes(lista)

    },[id_inst,nombre])

  return (
      <React.Fragment>
          <Helmet>
              <meta property="og:title" content={`Contratos Tren Maya de ${nombre}`}/>
              <meta property="og:description"
                    content={`Aqui puedes consultar y visualizar los contratos para el proyecto Tren Maya celebrados por ${nombre}`}/>
              <meta name="description"
                    content={`Aqui puedes consultar y visualizar los contratos para el proyecto Tren Maya celebrados por ${nombre}`} />
              <title>{`Contratos Tren Maya de ${nombre}`}</title>
          </Helmet>

          <Container maxWidth="lg" className={'section-content'}>
            <Grid container className='content'>

                <Grid container className={classes.root}>

                    <Grid item xs={12} sm={4}>
                        <CardCont
                            number={contratos.length}
                            tag='Número de contratos' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CardCont
                            number={proveedores.size}
                            tag='Número de proveedores' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <CardCont
                            number={ Intl.NumberFormat('en-US',{style:'currency',currency:'USD',minimunFractionDigits:2}).format(total)}
                            tag='Monto total'/>
                    </Grid>

                </Grid>

                <TableContent lista={contratos}/>

            </Grid>
          </Container>
      </React.Fragment>

   );

 }
