import React from 'react';
import {Container, Grid} from '@material-ui/core';

import TableContent from '../components/TableContent';

import all from "../contratos.json"
import CardCont from "../components/CardCont";
import {makeStyles} from "@material-ui/core/styles";
// import getImportes from "../services/getImportes";
import {removeAccents,removecommas} from "../services/getList";
import {Helmet} from "react-helmet";


const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20,
        marginBottom: 20,
    },
}));

export default function Proveedor(props){
    const classes = useStyles()
    const path = props.location.pathname
    const [,,id_Prov] = path.split("/");
    const [nombre, setNombre] = React.useState([]);
    const [contratos, setContratos] = React.useState([]);
    const [instituciones, setInstituciones] = React.useState([]);
    const [total, setTotal] = React.useState([]);

  React.useEffect( function() {

    const item = all.filter( item => 
    removecommas( removeAccents(item['Proveedor o contratista'].replace(/\s/g, '-')) ) === id_Prov.toString() )
    setNombre(item[0]['Proveedor o contratista'])

    const lista = all.filter( contrato =>
        contrato['Proveedor o contratista'] === nombre)
    setContratos(lista)

    const instituciones = new Set ( lista.map( item => item['Institución'] ) )
    setInstituciones(instituciones)

    const total = lista.reduce((a,b)=> a + (+b['Importe del contrato']),0 )
    setTotal(total)

    // const totales = getImportes(lista)

  },[id_Prov,nombre])

  return (
      <React.Fragment>
          <Helmet>
              <meta property="og:title" content={`Contratos Tren Maya del proveedor ${nombre}`}/>
              <meta property="og:description"
                    content={`En este micrositio puedes consultar y visualizar los contratos que se han celebrado para el proyecto Tren Maya por parte del proveedor o contratista ${nombre}`}/>
              <meta name="description"
                    content={`En este micrositio puedes consultar y visualizar los contratos que se han celebrado para el proyecto Tren Maya por parte del proveedor o contratista ${nombre}`}/>
              <title>{`Contratos Tren Maya del proveedor ${nombre}`}</title>
          </Helmet>
          
          <Container maxWidth="lg" className={'section-content'}>
            <Grid container className='content'>

              <Grid container className={classes.root}>

                  <Grid item xs={12} sm={4}>
                      <CardCont
                          number={contratos.length}
                          tag='Número de contratos' />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                      <CardCont
                          number={instituciones.size}
                          tag='Número de intituciones' />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                      <CardCont
                          number={Intl.NumberFormat('en-US',{style:'currency',currency:'MXN',minimunFractionDigits:2}).format(parseFloat(total)) }
                          tag='Monto total'/>
                  </Grid>

              </Grid>

              <TableContent lista={contratos}/>

          </Grid>
        </Container>
      </React.Fragment>

   );
 }
