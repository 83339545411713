import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from '@material-ui/core'


const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        backgroundColor: '#f2f2f2',
        paddingBottom: 30,
        textAlign: 'center',
        color: '#000',
    },
    bg_dark: {
        backgroundColor: "#4e4e4e",
        color: "#fff",
        fontSize: "12px",
        marginBottom: 60,
        marginTop: 60,
    }
}));

export default function Footer(){
    const classes = useStyles()
    const fecha = "14 de Julio de 2021"

    return(
        <React.Fragment>
            <div className={classes.root}>
                <div class={classes.bg_dark}>
                    <p>Fecha de actualización: {fecha}</p>
                </div>
                <Grid container alignItems="center" >
                    <Grid item xs={12} sm={6}>
                        <p>hola@serendipia.digital</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p>
                            <a href="https://serendipia.digital/" class="serendipia-logo">sp</a>
                        </p>
                        <p>
                            <a href="https://twitter.com/serendipiadata" class="sp_ficon tw">tw</a>
                            <a href="https://www.tiktok.com/@serendipiadata" class="sp_ficon tiktok">tk</a>
                            <a href="https://www.facebook.com/SerendipiaData/" class="sp_ficon fb">fb</a>
                            <a href="https://instagram.com/SerendipiaData" class="sp_ficon insta">ig</a>
                            <a href="https://m.youtube.com/c/SerendipiaData" class="sp_ficon yt">yt</a>
                        </p>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}