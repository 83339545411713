import React from "react";
import {Helmet} from "react-helmet";
import {Container, Grid} from '@material-ui/core'
import CardCont from "../components/CardCont";
import {makeStyles} from "@material-ui/core/styles";
import InfoCard from "../components/InfoCard";

import contratos from '../contratos.json'

const useStyles = makeStyles(() => ({
    root: {
        root: {
            marginTop: 20,
            marginBottom: 20,
        },
    },
}));

export default function Contrato(props){
    const classes = useStyles()
    const path = props.location.pathname
    const [,,clave] = path.split("/");

    const contrato = contratos.filter( contrato =>
        contrato['Código del contrato'].toString() === clave.toString())

    return (
        <React.Fragment>
            <Helmet>
            <meta property="og:title" content={`Contrato ${'Num'}`}/>
                <meta property="og:description"
                      content={`Visualiza los detalles del contrato ${'Num'} celebrado para el proyecto Tren Maya`}/>
                <meta name="description"
                      content={`Visualiza los detalles del contrato ${'Num'} celebrado para el proyecto Tren Maya`} />
                <title>Contrato {'Num'}</title>
            </Helmet>


            <Container maxWidth="lg" className={'section-content'}>
                <Grid container>
                    <Grid container className={classes.root}>

                        <Grid item xs={12} sm={4}>
                            <CardCont
                                number={contrato[0]['Proveedor o contratista']}
                                tagTittle={contrato[0]['RFC']}
                                tag='Proveedor o contratista'/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardCont
                                number={contrato[0]['Siglas de la Institución']}
                                tagTittle={contrato[0]['Institución']}
                                tag='Institución'/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardCont
                                number={ Intl.NumberFormat('en-US',{style:'currency',currency:'USD',minimunFractionDigits:2}).format(contrato[0]['Importe del contrato']) }
                                tag='Importe del contrato'/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardCont
                                number={contrato[0]['Fecha de inicio del contrato']}
                                tag='Fecha de inicio del contrato'/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardCont
                                number={contrato[0]['Fecha de fin del contrato']}
                                tag='Fecha de fin del contrato'/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardCont
                                number={contrato[0]['Estatus del contrato']}
                                tag='Estatus del contrato'/>
                        </Grid>

                        <Grid item className={classes.root} xs={12} sm={12}>
                            <InfoCard contrato={contrato[0]}/>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>


        </React.Fragment>
    );
}