import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: 150,
        margin: 7,
        textAlign: 'center',
    },
    tag: {
        fontSize: 12,
    },
    number: {
        fontSize: 22,
        margin: 10,
        fontWeight: 'bold',
    },
    boxBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    colorPrimary: {
        backgroundColor: theme.palette.primary.mainBlue[theme.palette.primary.light],
    },
    link: {
        color: theme.palette.primary.dark,
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.common.black
        }
    },
    tagnote: {
        fontSize: 10,
    }
}));



const LinearProgressWithLabel = (props) => {
    const classes = useStyles()
    var tag = props.value

    return (
        <Box className={classes.boxBar} alignItems="center">
            <Box width='70%' mr={1}>
                <LinearProgress className={classes.colorPrimary} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${
                    tag.toFixed(2)       
                }%`}</Typography>
            </Box>
        </Box>
    );
}

export default function CardCont(props){
    const classes = useStyles();
    var res = props.stats
    const pathto = props.pathto

    return (
      <Card className={classes.root}>
        <CardContent>
            <Typography className={classes.tag}>{props.tagTittle} </Typography>
            {
                pathto === undefined ? (
                    <h4 className={classes.number}>{props.number}</h4>
                ): (
                    <Link className={classes.link} to={ `/${pathto}` } >
                        <h4 className={classes.number}>{props.number}</h4>
                    </Link>
                )
            }
          <span className={classes.tag}>{props.tag}</span>
        </CardContent>
          <Divider />
          {
              props.stats !== undefined ? (
                  <div>
                      <LinearProgressWithLabel value={res} />
                      <span className={classes.tag}>{props.tagStats}</span>
                  </div>
              ) : null
          }
          {
              props.notedivider !== undefined ? (
                  <div>
                      <span className={classes.tagnote}>{props.notedivider}</span>
                  </div>
              ) : null
          }
      </Card>
    );
}
