import React from "react";
import {Helmet} from "react-helmet";
import {Container, Grid} from "@material-ui/core";

import SearchCont from "../components/SearchCont";


export default function ContratosHome(){
    return (
        <React.Fragment>
            <Helmet>
                <meta property="og:title" content="Buscador de Contratos"/>
                <meta property="og:description"
                      content="Explora los contratos celebrados del 2018 al 2021 por el gobierno federal para el proyecto Tren Maya"/>
                <meta name="description"
                      content="Explora los contratos celebrados del 2018 al 2021 por el gobierno federal para el proyecto Tren Maya" />
                <title>{`Buscador de Contratos`}</title>
            </Helmet>
            <Container maxWidth="lg" className={'section-content'}>
                <Grid container>


                    <Grid item xs={12} sm={12}>
                        <h3 className={'secondary-header'}> Contratos Tren Maya </h3>
                    </Grid>

                    <SearchCont />

                </Grid>

            </Container>
            

        </React.Fragment>
    );
}