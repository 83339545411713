import {Container, Grid, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import React from 'react';
import SPLogo from '../images/Negro-logo-contratos-tren-maya.svg';

const useStyles = makeStyles((theme) => ({
    logo:{
        marginBottom: theme.spacing(4),
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
}));

export default function Hero(props){
    const classes = useStyles()

  return (
      <React.Fragment>
          <div className='hero-section-bg'>

              <Container maxWidth="md">
                  <Grid container alignItems="center" className={'hero-section'}>
                      <Grid item xs={12}>
                          <img className={classes.logo} src={SPLogo} alt="Logo Contratos Tren Maya" />
                      </Grid>

                      <Grid item xs={12}>
                          <Typography >
                          Desde 2018, el gobierno de México ha adjudicado contratos por 87 mil 303 millones de pesos para construir y operar el Tren Maya, uno de los proyectos prioritarios de esta administración.
Aquí puedes explorar todos los contratos y conocer cuánto dinero está gastando el gobierno, en qué lo está gastando, qué dependencias están comprando, con quienes está firmando los contratos y mediante qué mecanismos.
                          </Typography>
                      </Grid>

                  </Grid>

              </Container>

          </div>
      </React.Fragment>
  );
}
